import { template as template_e9912e6235aa4feeb72f7acd37f56875 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e9912e6235aa4feeb72f7acd37f56875(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
