import { template as template_ec61bd44b64e46498d7f69caf841eeeb } from "@ember/template-compiler";
const WelcomeHeader = template_ec61bd44b64e46498d7f69caf841eeeb(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
